(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/events/assets/javascripts/events-subpub.js') >= 0) return;  svs.modules.push('/components/core/events/assets/javascripts/events-subpub.js');

svs = svs || {};

(function(svs) {
  'use strict';

  svs.events = (function() {
    var cache = {};

    return {
      publish: function( topic,  args) {

        var subs = cache[topic];
        var len = subs ? subs.length : 0;

        while (len--) {
          subs[len](args || []);
        }
      },

      subscribe: function( topic,  callback) {

        if (!cache[topic]) {
          cache[topic] = [];
        }
        cache[topic].push(callback);
        return [topic, callback]; 
      },

      unsubscribe: function( handle,  callback) {

        var subs = cache[callback ? handle : handle[0]];
        var len = subs ? subs.length : 0;

        callback = callback || handle[1];

        while (len--) {
          if (subs[len] === callback) {
            subs.splice(len, 1);
          }
        }
      }
    };
  })();
})(svs);


 })(window);
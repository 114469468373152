(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/events/assets/javascripts/events.js') >= 0) return;  svs.modules.push('/components/core/events/assets/javascripts/events.js');

svs = svs || {};

(function(svs) {
  'use strict';

  function _indexOf(list, listener) {
    var i = list.length;
    while (i) {
      i--;
      if (list[i].listener === listener) {
        return i;
      }
    }
    return -1;
  }

  function _arrayClone(arr, i) {
    var copy = new Array(i);
    while (i--) {
      copy[i] = arr[i];
    }
    return copy;
  }

  svs.core = svs.core || {};

  svs.core.Events = function(handle) {
    var callbacks = {};

    var on = function(eventName, listener, context) {
      if (typeof listener !== 'function') {
        throw new TypeError('"listener" argument must be a function');
      }

      if (!callbacks[eventName]) {
        callbacks[eventName] = [];
      }

      if (_indexOf(callbacks[eventName], listener) < 0) {
        callbacks[eventName].push({ listener: listener, context: context });
      }
    };

    var off = function(eventName, listener) {
      if (typeof eventName !== 'string') {
        throw new TypeError('"eventName" argument must be a string');
      } else if (typeof listener !== 'function') {
        throw new TypeError('"listener" argument must be a function');
      }

      if (callbacks[eventName]) {
        var index = _indexOf(callbacks[eventName], listener);
        if (index > -1) {
          callbacks[eventName].splice(index, 1);
        }
        if (callbacks[eventName].length < 1) {
          delete callbacks[eventName];
        }
      }
    };

    var trigger = function(eventName, args) {
      if (callbacks[eventName]) {
        var len = callbacks[eventName].length;
        var listeners = _arrayClone(callbacks[eventName], len);
        for (var i = 0; i < len; i++) {
          listeners[i].listener.call(listeners[i].context || handle, args);
        }
      }
    };

    var setup = function(handle) {
      handle.on = on;
      handle.off = off;
      handle.trigger = trigger;
      return handle;
    };

    return setup(handle);
  };

  svs.utils = svs.utils || {};
  svs.utils.Events = svs.core.Events;
})(svs);


 })(window);